export default `
**Telefonnummer** - Bekräfta köp av telefonnummer - Ja eller Nej. Kostnad tillkommer med 1,10 kr per telefonnummer. Telefonlistan hämtas under fliken "Telemarketing" dagen efter passerad stopptid.

**Fastighetsbyrån kundcenter** erbjuder skräddarsydda paket där allt från att ringa, boka möte och lägga in uppgifter i FasIt ingår.

Tjänsten kan beställas på två olika sätt:
- Köp av ADR + telefonnummer och mötesbokning
- Köp av enbart telefonnummer och mötesbokning  

<br/>

Efter beställning skickas ett aviseringsmejl från Broker Market till "support@thylling.se" som sedan kontaktar er. I samband med detta kontaktar **Fastighetsbyrån kundcenter** er för att boka ett digitaltmöte för att diskutera målet med aktiviteten, budskap etc.  

Broker Market fakturerar kontoren direkt för köp av adresserade DR och telefonnummer. Fastighetsbyrån kundcenter fakturerar kontoren direkt för ringtjänsten.
`